<template>
  <div class="WorldRanking">
    <div class="top">
        <div class="__title">
        <span>{{title}}</span>
      </div>
    </div>
    <div class="average">
      <i class="el-icon-caret-right"> 美巡赛平均 TOUR Average <span>{{average}}</span></i>
    </div>
    <!--    表格数据-->
    <div class="__list">
      <div class="_list">
        <div class="list_child" >
          <div class="line-thead"  v-for="(item,index) in cnThead[title]" :key="index">
            {{item}}
            <br/>
            {{enThead[title][index]}}
          </div>
        </div>
        <div class="list_child"
             :class="{'bg':index%2===0}"
             v-for="(item, index) in list" :key="index">
          <div class="line02">{{item.curRank}}</div>
          <div class="line02">{{item.prevRank}}</div>
          <div class="line-thead line02_other">
            {{item.player_name}}
            <br/><span class="plaer_nameEn">{{item.e_name}}</span>
          </div>
          <div class="line02" v-if="title!=='赛事前十次数 TOP 10 FINISHES'">{{item.statValues.rndEvents}}</div>
          <div class="line02">{{item.statValues.statValue1}}</div>
          <div class="line02">{{item.statValues.statValue2}}</div>
          <div class="line02">{{item.statValues.statValue3}}</div>
          <div class="line02" v-if="title=='平均成绩 SCORING AVERAGE'||title=='发球台到果岭得分 SG: TEE-TO-GREEN'||title=='综合得分 SG: TOTAL'||title=='推杆得分 SG: PUTTING'">
            {{item.statValues.statValue4}}
            </div>
          <div class="line02"  v-if="title=='赛事前十次数 TOP 10 FINISHES'||title=='标准杆上果岭率 GREENS IN REGULATION PERCENTAGE'||title=='发球台到果岭得分 SG: TEE-TO-GREEN'||title=='综合得分 SG: TOTAL'">
            {{item.statValues.statValue5}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pgaApi from "../../../request/pgaApi";
  import { staCnThead, staEnThead } from '../../../config/statistics'
      
  export default {
    name: "WorldRanking",
    components: { },
    data() {
      return {
        isShow_match:false,
        list:[],
        staId:'',
        title:'',
        average:'',
        cnThead: staCnThead,
        enThead: staEnThead,
      }
    },
    mounted() {
      //console.log(this.$route.query)
      if(this.$route.query){
        this.staId = this.$route.query.staId
        this.title = this.$route.query.pageTitle
        this.getList( this.staId )
      } 
    },

    methods: {
      async getList( id ){
        let data = await pgaApi({
          url:'/getPgaSta',
          data:{
            year:2023,
            staId:id
          }
        })
        this.list = []
        if(data!==null){
          this.list = data.sta
          this.average = data.average
        } 
      }
    }
  }
</script>

<style scoped lang="less">
  .WorldRanking{
    width: 1225px;
    padding-top: 40px;
    .top{
      padding-left: 10px;
      .__title{
        font-size: 28px;
        span{
          font-family: 'Roboto Condensed',sans-serif;
          font-weight: 400;
          letter-spacing: .25px;
        }
      
      }
    }

    .__list{
      padding-top: 20px;
      padding-bottom: 20px;
      .__top{
        padding-left: 30px;
        font-size: 16px;
        padding-bottom: 20px;
        span{
          //font-family: My_Medium;
        }
        span:nth-child(2){
          //font-family: My_Medium;
          font-weight: 600;
        }
      }
      

      ._list{
        font-size: 14px;
        .list_child{
          display: flex;
          text-align: center;
          line-height: 50px;
          border: 1px solid #eeeeee;
          .line01{
            //font-family: My_Medium;
            width: 120px;
            border-right: 1px solid #eeeeee;
          }
          .line02{
            //font-family: My_Medium;;
            width: 400px;
            text-align: center;
            padding: 20px 0;
            border-right: 1px solid #eeeeee;
          }
          .line02_other{
            //font-family: My_Medium;
            color: #003e7e;
          }
          .plaer_nameEn{
            color: #333333;
            font-weight: 600;
          }
          .line-thead{
            width: 400px;
            padding: 20px 0;
            text-align: center;
            line-height: 25px;
            border-right: 1px solid #eeeeee;
          }
          .w400{ width: 400px;}
          .w120{ width: 120px;}
        }
        .list_child:nth-child(1){
          background-image: linear-gradient(#ffffff 25px, #ebebeb);
          div{
            //font-family: My_Medium;
          }
        }
        .bg{
          background-color: #f2f2f2;
        }
      }
    }

    .average{
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 10px 0 10px 10px;
      line-height: 18px;
      vertical-align: inherit;
      span{
        //font-family: 'Roboto Condensed',sans-serif;
        font-weight: 700;
      }
    }
  }
</style>