export const  staCnThead = 
{ 
    '赛事前十次数 TOP 10 FINISHES':['本周排名 ','上周排名', '球员名称','前十次数','冠军次数','亚军次数','季军次数'],
    '平均成绩 SCORING AVERAGE':['本周排名 ','上周排名', '球员名称','参赛轮数','平均成绩','总杆数','调整总数','总轮数'],
    '开球距离 DRIVING DISTANCE':['本周排名 ','上周排名', '球员名称','参赛轮数','平均距离','总距离','开球次数'],
    '上球道率 DRIVING ACCURACY PERCENTAGE':['本周排名 ','上周排名', '球员名称','参赛轮数','上球道率','上球道次数','球道总数'],
    '标准杆上果岭率 GREENS IN REGULATION PERCENTAGE':['本周排名 ','上周排名', '球员名称','参赛轮数','标准杆上果岭率','上果岭次数','总洞数#','与帕相关'],
    '发球台到果岭得分 SG: TEE-TO-GREEN':['本周排名 ','上周排名', '球员名称','参赛轮数','平均得分','开球得分','攻果岭得分','果岭周边得分','统计轮数'],
    '综合得分 SG: TOTAL':['本周排名 ','上周排名', '球员名称','参赛轮数','平均得分','综合总得分','发球台到果岭总得分','推杆总得分','统计轮数'],
    '推杆得分 SG: PUTTING':['本周排名 ','上周排名', '球员名称','参赛轮数','平均得分','开球得分','推杆总得分','统计轮数'],
    '救球成功率 SCRAMBLING':['本周排名 ','上周排名', '球员名称','参赛轮数','救球成功率','帕或更加','错失标准杆上果岭'],
}

export const staEnThead = 
{ 
    '赛事前十次数 TOP 10 FINISHES':['RANK THIS WEEK ','RANK LAST WEEK', 'PLAYER NAME','TOP 10','1ST','2ND','3RD'],
    '平均成绩 SCORING AVERAGE':['RANK THIS WEEK ','RANK LAST WEEK', 'PLAYER NAME','ROUNDS','AVG','TOTAL STROKES','TOTAL ADJUSTMENT','TOTAL ROUNDS'],
    '开球距离 DRIVING DISTANCE':['RANK THIS WEEK','RANK LAST WEEK', 'PLAYER NAME','ROUNDS','AVG.','TOTAL DISTANCE','TOTAL DRIVES'],
    '上球道率 DRIVING ACCURACY PERCENTAGE':['RANK THIS WEEK ','RANK LAST WEEK', 'PLAYER NAME','ROUNDS','%','FAIRWAYS HIT','POSSIBLE FAIRWAYS'],
    '标准杆上果岭率 GREENS IN REGULATION PERCENTAGE':['RANK THIS WEEK ','RANK LAST WEEK', 'PLAYER NAME','ROUNDS','%','GREENS HIT','HOLES','RELATIVE/PAR'],
    '发球台到果岭得分 SG: TEE-TO-GREEN':['RANK THIS WEEK ','RANK LAST WEEK', 'PLAYER NAME','ROUNDS','AVERAGE','SG:OTT','SG:APR','SG:ARG','MEASURED ROUNDS'],
    '综合得分 SG: TOTAL':['RANK THIS WEEK ','RANK LAST WEEK', 'PLAYER NAME','ROUNDS','AVERAGE','TOTAL SG:T','TOTAL SG:T2G','TOTAL SG:P','MEASURED ROUNDS'],
    '推杆得分 SG: PUTTING':['RANK THIS WEEK ','RANK LAST WEEK', 'PLAYER NAME','ROUNDS','AVERAGE','TOTAL SG:PUTTING','MEASURED ROUNDSG'],
    '救球成功率 SCRAMBLING':['RANK THIS WEEK ','RANK LAST WEEK', 'PLAYER NAME','ROUNDS','%','PAR OR BETTER','MMISSED GIR'],
}
